

<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<button @click="showAddEdit()" class="pull-right btn btn-success">
				<i class="fa fa-plus"></i> <b v-if="totalPendingImage">{{totalPendingImage}}</b>
			</button>
			<button @click="loadLazyData" style="margin-right: 6px;" class="pull-right btn btn-info"
				title="Reload listing"><i class="fa fa-refresh"></i></button>
			<div class="pull-right" style="margin-top: 10px; margin-right: 15px">Last Update: {{ format(refreshTime,
					"hh:mma")
			}}</div>
			<div class="title">Manage Radiology Reports</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="id" header="Case ID" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<!-- {{data['id']}} -->
						<span
							style="border: 1px solid gray; border-radius: 6px; padding: 4px; font-weight: bold; ">{{ data['id'] }}</span>
					</div>
				</template>
			</Column>
			<Column field="patient_name" header="Patient Name" filterField="patient_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Patient Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<b>{{ data['patient_name'] }}</b>
					</div>
				</template>
			</Column>
			<!-- <Column field="patient_age" header="Patient Age" filterField="patient_age" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Patient Age"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['patient_age']}}
                    </div>
                </template>
            </Column> -->
			<!-- <Column field="patient_gender" header="Patient Gender" filterField="patient_gender" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <select v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control">
                            <option value="">Select Patient Gender</option>
                            
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['patient_gender']}}
                    </div>
                </template>
            </Column> -->
			<!-- <Column field="ref_doctor_name" header="Patient Ref Doctor" filterField="ref_doctor_name" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Patient Ref Doctor"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['ref_doctor_name']}}
                    </div>
                </template>
            </Column> -->
			<Column field="test_name" header="Test Name" filterField="test_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Test Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['test_name'] }}
					</div>
				</template>
			</Column>
			<!-- <Column field="history" header="History" filterField="history" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="History"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['history']}}
                    </div>
                </template>
            </Column> -->
			<Column field="reg_no" header="Reg No" filterField="reg_no" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Reg No" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['reg_no'] }}
					</div>
				</template>
			</Column>
			<!-- <Column field="mobile_no" header="Mobile No" filterField="mobile_no" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Mobile No"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['mobile_no']}}
                    </div>
                </template>
            </Column> -->
			<Column field="assign_doc_name" header="Radiologist" filterField="assign_doc_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Assign To Doctor" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['assign_doc_name'] ? data['assign_doc_name'] : "Any Doctor" }}
					</div>
				</template>
			</Column>
			<!-- <Column field="test_date" header="Test Date" filterField="test_date" :sortable="true">
                <template #filter="{filterModel,filterCallback}">
                    <div>
                        <input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control" placeholder="Test Date"/>
                    </div>
                </template>
                <template #body="{data}">
                    <div class="tdata">
                        {{data['test_date'].split("T")[0]}}
                    </div>
                </template>
            </Column> -->
			<Column field="created_at" header="Created At" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" style="width: 65px; margin: auto;">
						{{ format(data['created_at'], "DD/MM/YY h:mmA") }}
					</div>
				</template>
			</Column>
			<Column field="reported_at" header="Reported At" filterField="reported_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Reported At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<div v-if="data['reported_at']" style="width: 115px; margin: auto; text-align: center;">
							{{ format(data['reported_at'], "DD/MM/YY h:mmA") }}

							<div v-if="data?.tat" :class="{'text-success': data?.tatColor == 'green', 'fw-bold': data?.tatColor == 'green', 'text-primary': data?.tatColor == 'blue', 'text-danger': data?.tatColor == 'red'}">
								{{ data?.tat }}
							</div>
						</div>
						<div v-else-if="data['reportEstimateCounter']">
							<div class="text-primary text-center">{{ data['reportEstimateCounter'] }}</div>
						</div>
					</div>
				</template>
			</Column>
			<Column field="status" header="Status" filterField="status" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Status" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata text-center" style="padding-top: 8px;">
						<sup v-if="data['report'] && data['status'] == 'pending'"
							class="text-success pull-right">draft</sup>

						<div v-if="data['status'] == 'pending'">
							<span class="form-control p-0 w-75 text-center"
								style="background: #fd6d7b; color: #fff; padding: 5px; border-radius: 5px;">Pending</span>
						</div>
						<div v-else-if="data['status'] == 'review'">
							<span class="form-control p-0 w-75 text-center"
								style="background: blue; color: #fff; padding: 5px; border-radius: 5px;">Review</span>
						</div>
						<div v-else-if="data['status'] == 'substandard'">
							<span class="form-control p-0 text-center"
								style="background: red; color: #fff; padding: 5px; border-radius: 5px;">Substandard</span>
						</div>
						<div v-else>
							<select style="border-radius: 4px;" @change="changeStatus(data)" v-model="data['status']"
								class="form-control p-0 w-75 text-center" :class="{ 'bg-warning': data['status'] == 'reported' }"
								:style="{ background: data['status'] == 'printed' ? '#23d84a' : 'white' }" :disabled="data.review_id>0">
								<option style="background: #fff;" :selected="data['status'] == 'reported'" value="reported">
									Reported</option>
								<option style="background: #fff;" :selected="data['status'] == 'printed'" value="printed">Printed
								</option>
							</select>
						</div>

						<div v-if="data['showota']" style="text-align: left">
							<button type="button" class="btn btn-outline-warning" title="Open to all" @click="openToAll(data)">
								<i class="fa fa-clock-o"></i>
								OTA
							</button>
						</div>
						<div v-if="data['otacounter']" style="text-align: left">
							{{ data['otacounter'] }}
						</div>
					</div>
				</template>
			</Column>
			<Column field="urgent" header="Urgent" filterField="urgent" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="y / n" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata text-center">
						<div v-if="data['status'] == 'pending'">
							<Checkbox @change="switchUrgent(data)" v-model="data['urgent']" :binary="true" />
							<!-- <InputSwitch @change="switchUrgent(data)" v-model="data['urgent']" /> -->
						</div>
					</div>
				</template>
			</Column>
			<Column field="review_notes" header="Review" filterField="review_notes" :sortable="false">
				
				<template #body="{ data }">
					<div class="tdata">
						<div v-if="data['status'] == 'reported' || data['status'] == 'review'">
							<div v-if="data.review_done == 'n'">
								<!-- <InputSwitch title="Review" @change="openReviewModal(data)"
									:disabled="data['is_review']" v-model="data['is_review']" /> -->
									<!-- @change="data['show_review_comment'] = true" -->

								<div v-if="data['status'] == 'reported'">
									<button title="Review" type="button" class="btn btn-sm" :class="{'btn-outline-info': !data.review_id, 'btn-outline-danger': !!data.review_id}" @click="openReviewModal(data)">
										<i class="fa fa-eye"></i>
									</button>
								</div>
								
								<div v-if="data['show_review_comment']">
									<textarea v-model="data['review_notes']" placeholder="Review Comment To Doctor"></textarea>

									<button type="button" @click="sendReview(data)"
										class="btn btn-sm sm-btn btn-primary">Save</button>
								</div>
							</div>
							<div v-if="data.review_done == 'y'" :title="data['review_notes']">
								<i style="font-size: 30px" class="fa fa-thumbs-up text-success"></i>
							</div>
						</div>
						<!-- <InputSwitch v-model="data['review']" /> -->
					</div>
				</template>
			</Column>
			<Column
				header="Options &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;">
				<template #body="{ data }">
					<div class="tdata options">
						<button title="Notes" class="btn btn-sm btn-outline-primary" :style="{ color: data.notes ? 'red' : '' }"
							@click="showNotesBox(data)"><i class="fa fa-sticky-note"></i></button>
						<button title="Edit" class="btn btn-sm btn-outline-warning" @click="showAddEdit(data)"><i
								class="fa fa-edit"></i></button>
								
						<button v-if="data.status == 'reported'" title="Print" type="button" class="btn btn-sm btn-outline-success" @click="printReport(data)">
							<i class="fa fa-print"></i>
						</button>

						<button v-if="data.status == 'reported'" title="Print" type="button" class="btn btn-sm btn-outline-success" @click="printReport(data, true)">
							<i class="fa-solid fa-x-ray"></i>
						</button>

						
						<!-- <router-link v-if="data.status == 'reported'" :to="'/center/print-xray-report/' + data.id">
							<button title="Print" type="button" class="btn btn-sm btn-outline-success">
								<i class="fa fa-print"></i>
							</button>
						</router-link> -->
						<a v-if="data.mobile_no && (data.status == 'reported' || data.status == 'printed')"
							class="btn btn-sm btn-outline-info ms-1"
							:href="'https://api.whatsapp.com/send?phone=91' + (data.mobile_no.substr(-10)) + '&text=' + encodeURIComponent(apiOrigin + '/print-xray-report?data=' + encodeBase64(data.center_id+','+data.id) )"
							target="_blank">
							<i class="fa fa-whatsapp"></i>
						</a>
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="notesModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Notes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div>
						<textarea v-model="notes" rows="6" class="form-control"></textarea>
					</div>
					<div class="modal-footer">
						<button type="button" @click="saveNotes" class="btn btn-primary">Save</button>
					</div>
				</div>
			</div>
		</div>


		<div class="modal fade" ref="reviewModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Review</h5>
						<button type="button" :disabled="saving" @click="closeReviewModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div style="padding: 10px;">
						<div class="form-group">
							<textarea v-model="reviewNotes" class="form-control" placeholder="Please provide detailed review reason for doctor convenience."></textarea>
						</div>
						<div style="text-align: center;">
							Or
						</div>
						<div>
							<div v-if="!reviewAudioUrl">
								<button v-if="!isRecording" @click="startRecording" type="button" class="btn btn-info">
									<i class="fa fa-microphone"></i>
								</button>
								<span v-if="!isRecording"> &nbsp; Record review reason</span>
								<button v-if="isRecording" @click="stopRecording('review')" type="button" class="btn btn-info">
									<i class="fa fa-stop"></i>
								</button>
								<span v-if="isRecording"> &nbsp; Recording...</span>
							</div>
							<div v-if="reviewAudioUrl" style="display: flex;">
								<div style="width: 100%">
									<audio style="display: block; width: 100%" :src="reviewAudioUrl" controls></audio>
								</div>
								<div style="padding-left: 10px;">
									<span @click="reviewAudioFile = null; reviewAudioUrl = null; edit_review_audio_file = ''" class="fa fa-times text-danger" style="cursor: pointer; font-size: 33px; line-height: 52px;"></span>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" @click="sendReview" class="btn btn-primary">Submit Review</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" ref="deleteModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-danger text-white">
						<h5 class="modal-title">Delete Confirmation</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">
						<div class="text-danger" style="font-weight: bold; text-align: left; margin-bottom: 8px;">Record ID:
							{{ editId }}</div>
						You are about to delete this record, Are you sure?
						<br>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="deleteRecord">Delete</button>
					</div>
				</div>
			</div>
		</div>

		<form action="" @submit.prevent="putRecord">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								<div v-if="editId == 0">Add Patient (Radiology)</div>
								<div v-else>Edit Patient</div>
							</h5>
							<button type="button" @click="closeAddModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="container">
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.patient_name?.hasError && fcontrol.patient_name.touched }"
												v-model="fdata.patient_name" @blur="fcontrol.patient_name.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.patient_name?.hasError">
												<span v-if="fcontrol.patient_name.errors.required">You must enter patient
													name</span>
												<span v-if="fcontrol.patient_name.errors.max">Patient Name can contain maximum
													{{ fcontrol.patient_name.errors.max.maxLength }} characters</span>
											</div>
											<label>Patient Name</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.patient_age?.hasError && fcontrol.patient_age.touched }"
												v-model="fdata.patient_age" @blur="fcontrol.patient_age.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.patient_age?.hasError">
												<span v-if="fcontrol.patient_age.errors.required">You must enter patient age</span>
												<span v-if="fcontrol.patient_age.errors.max">Patient Age can contain maximum
													{{ fcontrol.patient_age.errors.max.maxLength }} characters</span>
											</div>
											<label>Patient Age</label>
										</div>
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.patient_gender?.hasError && fcontrol.patient_gender.touched }"
												v-model="fdata.patient_gender" @blur="fcontrol.patient_gender.touched = true">
												<option value="" disabled selected>Select Patient Gender</option>
												<option value="male">Male</option>
												<option value="female">Female</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.patient_gender?.hasError">
												<span v-if="fcontrol.patient_gender.errors.required">You must enter patient
													gender</span>
												<span v-if="fcontrol.patient_gender.errors.in">Invalid patient gender</span>
											</div>
											<label>Patient Gender</label>
										</div>
										<div class="form-group ui-float-label">
											<div class="p-fluid">
												<AutoComplete placeholder="Referral Doctor" v-model="fdata.ref_doctor_name"
													:suggestions="refDoctors" @complete="searchRefDoctor($event)" field="name" />
											</div>
											<div class="invalid-feedback" :style="{ display: refDoctorInvalid ? 'block' : 'none' }">
												<span>You must enter Referral doctor</span>
											</div>
										</div>
										<div class="form-group ui-float-label">
											<div class="p-fluid">
												<AutoComplete forceSelection :dropdown="true" :disabled="editId != 0"
													placeholder="Select Test Name" v-model="fdata.test_id"
													:suggestions="filteredTests" @complete="searchTests($event)" field="name"
													@change="onTestSelect" @item-select="onTestSelect" />
											</div>
											<div class="invalid-feedback" :style="{ display: testNameInvalid ? 'block' : 'none' }">
												<span>You must select test name</span>
											</div>
										</div>
										<div class="form-group ui-float-label">
											<input type="date" class="form-control"
												:class="{ 'is-invalid': fcontrol.test_date?.hasError && fcontrol.test_date.touched }"
												v-model="fdata.test_date" @blur="fcontrol.test_date.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.test_date?.hasError">
												<span v-if="fcontrol.test_date.errors.required">You must enter test date</span>
											</div>
											<label>Test Date</label>
										</div>
										<div class="form-group ui-float-label">
											<!-- <input type="text" class="form-control" :class="{'is-invalid': fcontrol.history?.hasError && fcontrol.history.touched}" v-model="fdata.history" @blur="fcontrol.history.touched=true" placeholder=" "/> -->
											<textarea rows="3" :disabled="editRow?.status == 'reported'" class="form-control"
												:class="{ 'is-invalid': fcontrol.history?.hasError && fcontrol.history.touched }"
												v-model="fdata.history" @blur="fcontrol.history.touched = true"
												placeholder="Please provide patient health history & issues for better reporting.  कृपया बेहतर रिपोर्टिंग के लिए रोगी का स्वास्थ्य सम्बन्धित जानकारी  प्रदान करें।"></textarea>
											<div class="invalid-feedback" v-if="fcontrol.history?.hasError">
												<span v-if="fcontrol.history.errors.required">You must enter history</span>
												<span v-if="fcontrol.history.errors.min">History must contain at least {{ fcontrol.history.errors.min.minLength }} characters</span>
											</div>
											<!-- <label>History</label> -->
										</div>

										<div class="form-group">
											<div v-if="!historyAudioUrl">
												<button v-if="!isRecording" @click="startRecording" title="History Audio" type="button" class="btn btn-info">
													<i class="fa fa-microphone"></i>
												</button>
												<button v-if="isRecording" @click="stopRecording" type="button" class="btn btn-info">
													<i class="fa fa-stop"></i>
												</button>
												<span v-if="isRecording"> &nbsp; Recording...</span>
											</div>
											<div v-if="historyAudioUrl" style="display: flex;">
												<div style="width: 100%">
													<audio style="display: block; width: 100%" :src="historyAudioUrl" controls></audio>
												</div>
												<div style="padding-left: 10px;">
													<span @click="historyAudioFile = null; historyAudioUrl = null; edit_history_audio_file = ''" class="fa fa-times text-danger" style="cursor: pointer; font-size: 33px; line-height: 52px;"></span>
												</div>
											</div>
										</div>

										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.reg_no?.hasError && fcontrol.reg_no.touched }"
												v-model="fdata.reg_no" @blur="fcontrol.reg_no.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.reg_no?.hasError">
												<span v-if="fcontrol.reg_no.errors.required">You must enter reg no</span>
												<span v-if="fcontrol.reg_no.errors.max">Reg No can contain maximum
													{{ fcontrol.reg_no.errors.max.maxLength }} characters</span>
											</div>
											<label>Reg No/ Bill No</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.mobile_no?.hasError && fcontrol.mobile_no.touched }"
												v-model="fdata.mobile_no" @blur="fcontrol.mobile_no.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.mobile_no?.hasError">
												<span v-if="fcontrol.mobile_no.errors.required">You must enter WhatsApp
													number</span>
												<span v-if="fcontrol.mobile_no.errors.max">WhatsApp number can contain maximum
													{{ fcontrol.mobile_no.errors.max.maxLength }} characters</span>
											</div>
											<label>WhatsApp Number</label>
										</div>


										

										<div class="card p-4" style="margin-top: 12px;">
											<div style="padding-bottom: 10px; font-weight: bold;">
												Select Doctor
											</div>

											<div style="display: flex">
												<div style="width: 100%;">
													<label>
														<input type="radio" v-model="fdata.select_doctor" name="select_doctor" value="any"
														:disabled="(editRow && editRow?.status != 'pending') || (editRow && editRow.assign_doc_id != '0' && editRow.report) || editRow?.accepted_at" > Any Available Doctor
													</label>
													<div style="padding-left: 17px; font-size: 12px" class="text-success">(Recomended)</div>
												</div>
												<div style="color: #666; width: 100%; font-size: 14px;">
													Choose this option for <b class="text-success">fast reporting time</b>
												</div>
											</div>

											<div style="display: flex; margin-top: 15px;">
												<div style="width: 100%;">
													<label>
														<input type="radio" v-model="fdata.select_doctor" name="select_doctor" value="specific"
														:disabled="(editRow && editRow?.status != 'pending') || (editRow && editRow.assign_doc_id != '0' && editRow.report) || editRow?.accepted_at"> Specific Doctor
													</label>
												</div>
												<div style="color: #666; width: 100%; font-size: 14px;">
													This may delay reporting time as per your selected doctor availability / workload
												</div>
											</div>

											<div class="form-group ui-float-label" v-if="fdata.select_doctor == 'specific'">
												<select class="form-control"
													:disabled="(editRow && editRow?.status != 'pending') || (editRow && editRow.assign_doc_id != '0' && editRow.report) || editRow?.accepted_at"
													:class="{ 'is-invalid': fcontrol.assign_doc_id?.hasError && fcontrol.assign_doc_id.touched }"
													v-model="fdata.assign_doc_id" @blur="fcontrol.assign_doc_id.touched = true">
													<option v-if="false" value="0">Any Available Doctors</option>
													<option v-for="d in radiologists" :key="d.id" :value="d.id">{{ d.name }}</option>
												</select>
												<div class="invalid-feedback" v-if="fcontrol.assign_doc_id?.hasError">
													<span v-if="fcontrol.assign_doc_id.errors.required">You must enter assign to
														doctor</span>
												</div>
												<label>Assign To Doctor</label>
											</div>
										</div>






									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<input type="file" id="image" :disabled="editRow?.status == 'reported'"
												accept="image/*" multiple style="display: none" @change="selectImages()"
												ref="images" />
											<label for="image" style="display: block">
												<div class="form-control">Select X-Ray Images</div>
											</label>
											<!-- <label>XRay Images</label> -->
										</div>
										<div class="images">
											<div v-for="image in images" :key="image">
												<i v-if="!(editRow?.status == 'reported')" @click="removeImage(image)"
													class="fa fa-close text-danger"></i>
												<img :src="image.url" alt="">
											</div>
										</div>
									</div>
									<div class="col-sm-12" v-if="saving">
										<ProgressBar :value="uploadPercentage">
											Sending: {{ uploadPercentage }}%
										</ProgressBar>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" @click="closeAddModal" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>



		<!-- <div style="width: 100%; text-align: center;  z-index: 10000; min-height: 100%; background: #fff; position: absolute; left:0; top:0">
            <div v-for="i in 100" :key="i">
                {{i}}
            </div>
        </div> -->
	</div>
</template>

<script>
import ProgressBar from 'primevue/progressbar';

import { Modal } from 'bootstrap'
import AutoComplete from 'primevue/autocomplete';
import Toast from 'primevue/toast';
// import InputSwitch from 'primevue/inputswitch';
import Checkbox from 'primevue/checkbox';

export default {
	components: {
		AutoComplete,
		Toast,
		// InputSwitch,
		Checkbox,
		ProgressBar
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			editRow: null,
			saving: false,
			uploadPercentage: 0,
			loadingAddModal: false,
			addModal: null,
			deleteModal: null,
			notesModal: null,
			reviewModal: null,
			lazyParams: {},
			refDoctors: null,
			tests: [],
			filteredTests: null,
			allRadiologists: [],
			radiologists: [],
			totalPendingImage: 0,
			images: [],
			/*
				 {
					  url: 
					  name:
					  file: 
				 }
			*/
			removedImages: [],
			refDoctorInvalid: false,
			testNameInvalid: false,
			center: {},
			refreshTime: new Date(),
			notes: '',
			isRecording: false,
			historyAudioFile: null,
			historyAudioUrl: null,
			edit_history_audio_file: '',

			reviewNotes: '',
			reviewAudioFile: null,
			reviewAudioUrl: null,

			updateInterval: null,


			filters: {
				'id': { value: '', matchMode: 'like' },
				'patient_name': { value: '', matchMode: 'like' },
				'patient_age': { value: '', matchMode: 'like' },
				'patient_gender': { value: '', matchMode: 'like' },
				'ref_doctor_name': { value: '', matchMode: 'like' },
				'test_name': { value: '', matchMode: 'like' },
				'test_date': { value: '', matchMode: 'like' },
				'reported_at': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
				'history': { value: '', matchMode: 'like' },
				'reg_no': { value: '', matchMode: 'like' },
				'mobile_no': { value: '', matchMode: 'like' },
				'assign_doc_name': { value: '', matchMode: 'like' },
				'status': { value: '', matchMode: 'like' },
				'urgent': { value: '', matchMode: 'like' },
				'review_notes': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				patient_name: '',
				patient_age: '',
				patient_gender: '',
				ref_doctor_name: '',
				test_id: '',
				test_date: '',
				history: '',
				reg_no: '',
				mobile_no: '',
				select_doctor: 'any',
				assign_doc_id: '0',
			},
			fvalidator: {
				patient_name: 'required|max:30',
				patient_age: 'required|max:15',
				patient_gender: 'required|in:male,female',
				// ref_doctor_name: 'required',
				// test_id: 'required',
				test_date: 'required',
				history: 'required|min:4',
				reg_no: 'max:40',
				mobile_no: 'max:15',
				select_doctor: 'required',
				assign_doc_id: 'required',
			}
		}
	},
	mounted() {
		this.addModal = new Modal(this.$refs.addModal, { backdrop: 'static', keyboard: false });
		this.deleteModal = new Modal(this.$refs.deleteModal,  { backdrop: 'static', keyboard: false });
		this.notesModal = new Modal(this.$refs.notesModal,  { backdrop: 'static', keyboard: false });
		this.reviewModal = new Modal(this.$refs.reviewModal,  { backdrop: 'static', keyboard: false });

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();

		this.get('center/load-tests').then(res => {
			this.tests = res;
		});
		
		this.get("center/load-center").then(res => {
			this.center = res;
		});

		this.loadRadiologists();
		this.loadPendingImageCount();


		setTimeout(()=>{
			soc.on('xrayReport', d => {
				this.loadLazyData();
				notiAudio.play();
			})

			soc.on('xrayImage', d => {
				if(d) notiAudio.play();
				this.loadPendingImageCount();
			})
		}, 0)

		this.updateInterval = setInterval(this.updateRows, 1000)

	},
	unmounted() {
		soc.off('xrayReport');
		soc.off('xrayImage');
		

		if(this.isRecording){
			micRecorder.stop();
			this.isRecording = false;
		}

		clearInterval(this.updateInterval);
	},
	methods: {

		loadRadiologists(){
			this.get("center/load-radiologists").then(res => {
				this.allRadiologists = res.filter(r => {
					return r.is_active == 'y';
				});
				this.allRadiologists.map(r => {
					if (r.online) r.name = r.name + ' (Online)';
				});
			});
		},

		async loadPendingImageCount(){
			let res = await this.get("center/get-pending-xray-image-count");
			this.totalPendingImage = res.total ? res.total : 0;
		},

		encodeURIComponent(data) {
			return window.encodeURIComponent(data)
		},
		encodeBase64(str){
			return btoa(str).replaceAll(/=/ig, "");
		},

		startRecording(){
			this.isRecording = true;
			micRecorder.start().then(() => {

			}).catch((e) => {
				console.error(e);
			});
		},
		stopRecording(type){
			this.isRecording = false;

			micRecorder.stop().getMp3().then(([buffer, blob]) => {
				const file = new File(buffer, 'audio.mp3', {
					type: blob.type,
					lastModified: Date.now(),
				});
				const url = URL.createObjectURL(file);

				if(type == 'review'){
					this.reviewAudioFile = file;
					this.reviewAudioUrl = url;
				}else{
					this.historyAudioFile = file;
					this.historyAudioUrl = url;
				}
			}).catch((e) => {
				console.error(e);
			});

		},

		closeAddModal(){
			if(this.isRecording){
				micRecorder.stop();
				this.isRecording = false;
			}
		},


		printReport(row, image=false){
			
			let data =  btoa(row.center_id+','+row.id).replaceAll(/=/ig, "");

			let query = {
				data
			};

			if(image) query['image'] = 'true';
			

			this.$router.push({
				path: "/print-xray-report",
				query
			})
		},

		openToAll(data){
			if(!confirm("For fast reporting, you can open this case to all doctors.\n\nPlease confirm to Open To All (OTA).")) return;

			this.post("center/xray-open-to-all", {
				id: data.id,
			}).then(res => {
				this.loadLazyData();
				if (res.success) {
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'This case is now open to all doctors.', life: 3000 });
				}else{
					this.$toast.add({ severity: 'error', summary: 'Failure', detail: 'Failed to open this case to all doctors.', life: 3000 });
				}
			});

		},


		openReviewModal(row){
			this.editId = row.id;
			this.editRow = row;
			this.reviewAudioFile = null;
			this.reviewAudioUrl = row.review_audio_file ? this.apiOrigin + "/" + 'images/radiology/' + row.review_audio_file : null;
			this.reviewNotes = row.review_notes ? row.review_notes : '';
			this.reviewModal.show();
		},
		closeReviewModal(){
			if(this.isRecording){
				micRecorder.stop();
				this.isRecording = false;
			}
		},
		async sendReview() {
			let fd = {
				id: this.editId,
				review_notes: this.reviewNotes ? this.reviewNotes : '',
				assign_doc_id: this.editRow.assign_doc_id,
			};
			if(!this.reviewNotes && !this.reviewAudioFile){
				return this.$toast.add({ severity: 'error', summary: 'Error', detail: 'You must enter review notes to send this report for review.', life: 3000 });
			}
			if(this.isRecording){
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Please finish recording before submitting the form.', life: 3000 });
				return;
			}

			if(this.reviewAudioFile){
				fd['reviewAudioFile'] = this.reviewAudioFile
			}

			this.saving = true;
			this.upload("center/send-xray-review", fd, (err, res) => {
				this.saving = false;
				if (res.success) {
					this.reviewModal.hide();
					this.reviewNotes = '';
					this.reviewAudioFile = null;
					this.reviewAudioUrl = null;
					this.loadLazyData();

					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Review request sent successfully.', life: 3000 });
				} else {
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Sorry something went worng, Please try again later.', life: 3000 });
				}
			}, p => {
				this.uploadPercentage = Math.round(p * 100);
			});




			// await this.post("center/send-xray-review", data);
			// this.loadLazyData();
			// this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Review request sent successfully.', life: 3000 });
		},


		showNotesBox(data) {
			this.notes = data.notes;
			this.editRow = data;
			this.notesModal.show();
		},
		saveNotes() {
			this.post("center/save-xray-notes", {
				notes: this.notes,
				report_id: this.editRow.id
			}).then(() => {
				this.notesModal.hide();
				this.loadLazyData();
			})
		},
		selectImages() {
			let files = this.$refs['images'].files;
			for (let i = 0; i < files.length; i++) {
				let img = files[i];

				var reader = new FileReader();
				reader.onload = e => {
					this.images.push({
						url: e.target.result,
						file: img
					});
				}
				reader.readAsDataURL(img);
			}
			this.$refs['images'].value = '';
		},
		removeImage(image) {
			this.images = this.images.filter(fimg => fimg != image);
			if (image.name){
				if(this.editId){
					this.removedImages.push(image.name);
				}else{
					this.post('center/delete-pending-xray-image', {image: image.name}).then(res=>{

					});
				}
			}
		},
		putRecord() {
			if (this.saving) return;
			let hasError = false;
			if (this.fHasError()) hasError = true;

			if (!this.fdata.ref_doctor_name) {
				hasError = true;
				this.refDoctorInvalid = true;
			} else {
				this.refDoctorInvalid = false;
			}
			if (!this.fdata.test_id?.id) {
				hasError = true;
				this.testNameInvalid = true;
			} else {
				this.testNameInvalid = false;
			}
			if (hasError) return;
			if (!this.editId && !this.images.length) return;

			if(this.fdata.select_doctor == 'specific' && (!this.fdata.assign_doc_id || this.fdata.assign_doc_id == '0')){
				this.$toast.add({ severity: 'error', summary: 'Select assign doctor', detail: 'Please select assign doctor.', life: 5000 });
				return;
			}

			if (!this.editId) {
				if (!confirm("The selected test name can not be changed. Please confirm to continue")) return;
			}

			if(this.fdata.select_doctor == 'any') this.fdata.assign_doc_id = '0';


			if(this.isRecording){
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Please finish recording before submitting the form.', life: 3000 });
				return;
			}


			let data = JSON.parse(JSON.stringify(this.fdata));
			data.test_id = this.fdata.test_id.id;
			if (typeof this.fdata.ref_doctor_name == 'object') data.ref_doctor_name = this.fdata.ref_doctor_name.name;


			this.saving = true;



			let images = [];

			let totalNewImages = 0;
			for (let image of this.images) {
				if (image.file) {
					data['image' + totalNewImages] = image.file;
					totalNewImages++;
				}
				if(image.name) images.push(image.name);
			}
			data.totalNewImages = totalNewImages;
			data.images = images.join(",");

			if(this.historyAudioFile){
				data.historyAudioFile = this.historyAudioFile;
			}

			data.history_audio_file = this.edit_history_audio_file;

			if (this.editId) {
				data.id = this.editId;
				data.removedImages = this.removedImages.join(",");
			}
			this.upload("center/put-xray-report", data, (err, res) => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.historyAudioFile = null;
					this.historyAudioUrl = null;
					this.loadLazyData();
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Report submitted successfully.', life: 3000 });
				} else if (res.lowBalance) {
					this.$toast.add({ severity: 'warn', summary: 'Low Balance', detail: 'You have low balance. Please recharge to continue', life: 3000 });
				} else if (res.duplicate) {
					this.$toast.add({ severity: 'warn', summary: 'Duplicate', detail: 'Duplicate case has been ignored.', life: 3000 });
				} else {
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Sorry something went worng, Please try again later.', life: 3000 });
				}
			}, p => {
				this.uploadPercentage = Math.round(p * 100);
			})
			// this.post("center/put-report", data).then(res => {

			// });
		},
		searchRefDoctor(event) {
			if (!event.query.trim().length) {
				this.refDoctors = [];
			} else {
				this.post('center/filter-ref-doctors', { prefix: event.query.toLowerCase() }).then(res => {
					this.refDoctors = res;
				})
			}
		},
		searchTests(event) {
			if (!event.query.trim().length) {
				this.filteredTests = [...this.tests];
			} else {
				this.filteredTests = this.tests.filter((test) => {
					return test.name.toLowerCase().indexOf(event.query.toLowerCase()) != -1;
				});
			}
		},
		onTestSelect(e){
			if(!this.fdata.test_id?.id){
				this.radiologists = [];
				return;
			}

			if(this.fdata.test_id.category == 'opg'){
				this.radiologists = this.allRadiologists.filter(r=>r.specialization == 'opg')
			}else if(this.fdata.test_id.category == 'multi_view' || this.fdata.test_id.category == 'other_view'){
				this.radiologists = this.allRadiologists.filter(r=>r.specialization == 'radiology_special')
			}else{
				this.radiologists = this.allRadiologists.filter(r=>r.specialization == 'radiology' || r.specialization == 'radiology_special')
			}

			if(!(this.editRow && this.editRow.report)){
				this.radiologists = this.radiologists.filter(r=>r.disable_direct_report == 'n');
			}

			if(!this.editId){
				setTimeout(()=>{
					this.fdata.assign_doc_id = this.center.prefer_radiologist_id+"";
				},0)
			}

		},

		async showAddEdit(row) {
			if(this.loadingAddModal) return;
			this.loadingAddModal = true;

			this.loadRadiologists();
			
			this.freset();
			this.refDoctorInvalid = false;
			this.testNameInvalid = false;

			this.images = [];
			this.removedImages = [];

			this.editId = row ? row.id : 0;
			this.editRow = row;
			
			if (this.center.prefer_radiologist_id) this.fdata.assign_doc_id = this.center.prefer_radiologist_id+"";
			if (row) {
				let images = row.images ? row.images.split(",") : [];
				for (let i of images) {
					this.images.push({
						url: this.apiOrigin + "/" + 'images/radiology/' + i,
						name: i,
					})
				}


				
				this.fdata.select_doctor = row.assign_type == 'all' ? 'any' : 'specific';

				row.assign_doc_id = String(row.assign_doc_id);
				console.log("row.assign_doc_id: ", row.assign_doc_id);
				row.test_date = row.test_date.split("T")[0]
				this.fpopulate(row);
				this.fdata.test_id = this.tests.find(r => r.id == row.test_id);
				// console.log(this.fdata.test_id);
				if(row.history_audio_file){
					this.historyAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + row.history_audio_file;
					this.edit_history_audio_file = row.history_audio_file;
				}else{
					this.edit_history_audio_file = '';
				}
				this.onTestSelect();
				// setTimeout(()=>{
				// },0);

			} else {
				this.fdata.test_date = new Date().toISOString().split("T")[0];
				this.edit_history_audio_file = '';


				if(this.totalPendingImage){
					let res = await this.get('center/get-pending-xray-image');

					let row = res.row;
					if(row){
						let images = res.images;
						for (let i of images) {
							this.images.push({
								url: this.apiOrigin + "/" + 'images/radiology/' + i,
								name: i,
							})
						}

						delete row.id;
						row.reg_no = row.patient_id;
						this.fpopulate(row);
					}
				}

			}
			this.addModal.show();
			this.loadingAddModal = false;
		},
		async changeStatus(data) {
			let res = await this.post("center/change-xray-status", data);
			if(res.success){
				this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Status changed successfully', life: 3000 });
			}else{
				data.status = 'reported';
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to change status', life: 3000 });
			}
		},
		switchUrgent(data) {
			let anyDoctor = false;

			if(data.urgent){
				anyDoctor = confirm("Change assignment to any doctor?");
			}
			this.post("center/change-xray-urgent", {
				id: data.id,
				urgent: data.urgent,
				anyDoctor,
			}).then(res => {
				this.loadLazyData();
				if (res.success) {
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Urgent status changed.', life: 3000 });
				}
			});
		},

		deleteRecord() {
			this.delete("center/delete-reports/" + this.editId).then(res => {
				this.saving = false;
				if (res.success) {
					this.deleteModal.hide();
					this.loadLazyData();
				}
			});
		},
		loadLazyData() {
			this.refreshTime = new Date();
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			params.joins = [
				{
					"fTable": "radiologists",
					"fKey": "assign_doc_id",
					"fField": [["name", "assign_doc_name"]]
				},
				{
					"fTable": "tests",
					"fKey": "test_id",
					"fField": [["name", "test_name"]]
				},
			]
			this.post("center/load-xray-reports", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;
					this.tableRecords = res.rows;

					let pendingReports = [];
					
					for (let row of res.rows) {
						row.urgent = row.urgent == 'y';
						row.is_review = row.status == 'review'

						if(row.reported_at){
							let ca = new Date(row.created_at).getTime();
							let ra = new Date(row.reported_at).getTime();

							let diff = (ra-ca)/1000;
							
							if(diff > 0){
								let days = Math.floor(diff / (24*60*60));
								diff = diff % (24*60*60);
								let hours = Math.floor(diff / ( 60*60 ));
								diff = diff % (60*60);
								let minutes = Math.floor(diff / 60);
								if( minutes < 2 ) minutes = 2;

								let time = [];
								if(days){
									time.push(days+' Day'+(days > 1 ? 's' : ''));
									row.tatColor = 'red';
								}else if(hours){
									time.push(hours+' Hour'+(hours > 1 ? 's' : ''));
									row.tatColor = 'red';
								}else if(minutes){
									time.push(minutes+' Minute'+(minutes > 1 ? 's' : ''));
									if(minutes < 30){
										row.tatColor = 'green';
									}else{
										row.tatColor = 'blue';
									}
								}

								time = time.join(" ");
								row.tat = time;
							}

						}

						if(row.status == 'pending'){
							pendingReports.push(row.id);
						}
					}
					if(pendingReports.length){
						this.post("center/load-pending-report-tat", {pendingReports}).then(res=>{
							if(res.success){
								for(let tat of res.tats){
									let row = this.tableRecords.find(i=>i.id == tat.id);
									if(row){
										row.reportEstimatedAt = tat.tat;
									}
								}
							}
						})
					}


				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		},
		updateRows(){
			if(!this.tableRecords) return;
			

			for(let row of this.tableRecords){

				if(row.reportEstimatedAt){
					let diff = new Date(row.reportEstimatedAt).getTime() - new Date().getTime();
					
					if(diff > 0){
						diff = Math.round(diff/1000);
	
						let min = Math.floor(diff/60);
						let sec = diff - min*60;
						
						min = String(min);
						sec = String(sec);
	
						if(min.length < 2) min = '0'+min;
						if(sec.length < 2) sec = '0'+sec;
						
						row.reportEstimateCounter = min+' : '+sec;
					}
				}

				continue;

				if(row.status == 'pending' && row.assign_type == 'specific' && !row.report){
					let ca = new Date(row.created_at).getTime();
					let diff = new Date().getTime() - ca;
					diff = Math.round(diff/1000);
					
					row.showota = false;
					row.otacounter = '';
					if(diff > 15*60){
						row.showota = true;
					}else{

						diff = ca + 15*60*1000 - new Date().getTime();
						diff = Math.round(diff/1000);

						let min = Math.floor(diff/60);
						let sec = diff - min*60;
						
						min = String(min);
						sec = String(sec);

						if(min.length < 2) min = '0'+min;
						if(sec.length < 2) sec = '0'+sec;
						
						row.otacounter = min+' : '+sec;
					}
				}
			}

		}
	}
}
</script>
<style scoped>
.images>div {
	position: relative;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	margin-bottom: 5px;
}

.images img {
	max-width: 100%;
	margin: auto;
	display: block;
}

.images>div .fa-close {
	font-size: 35px;
	/* color: red; */
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 10px;
}
</style>




